/* Media Queries */





/* Underline styles */
#Nav a {
	display: block;
	position: relative;
	padding: 0.1em 0;
}

/* Fade in */
#Nav a::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0.1em;
	background-color: rgb(254 240 138);;
	opacity: 0;
	transition: opacity 300ms, transform 300ms;
}

#Nav a:hover::after,
#Nav a:focus::after {
	opacity: 1;
	transform: translate3d(0, 0.1em, 0);
}


/* Scale from center */
#Nav a::after {
	opacity: 1;
	transform: scale(0);
	transform-origin: center;
}

#Nav a:hover::after,
#Nav a:focus::after{
	transform: scale(1);
}


.typed-cursor{
	opacity: 1;
	animation: typedjsBlink 0.7s infinite;
	-webkit-animation: typedjsBlink 0.7s infinite;
	animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink{
	50% { opacity: 0.0; }
}
@-webkit-keyframes typedjsBlink{
	0% { opacity: 1; }
	50% { opacity: 0.0; }
	100% { opacity: 1; }
}
.typed-fade-out{
	opacity: 0;
	transition: opacity .25s;
	-webkit-animation: 0;
	animation: 0;
	color:rgba(99, 99, 99, 0.24)
}
